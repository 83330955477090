// Gatsby supports TypeScript natively!
import React,{ useEffect,Component } from "react"
import Modal from "react-modal"
import { PageProps } from "gatsby"
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../../static/css/dataTables.bootstrap.css"
import "../../static/css/datatable-ajustes.css"
Modal.setAppElement("#___gatsby")
const jQuery = require("jquery")
const axios = require("axios")
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    // marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    background:'#313444'
  }
};
const PublicacionesLists3 = () => {

      useEffect(() => {
        window.slider();
        window.publicaciones();
    }, [])


return (
  <Layout title="Publicaciones" show_bread={true} >
    <SEO title="Publicaciones" />
    <div className="section mcb-section equal-height publicaciones" style={{paddingTop:'100px', paddingBottom:'60px' }} id="mainp">
        <div className={"section_wrapper mcb-section-inner"}>
            <div className={"wrap mcb-wrap one  valign-top clearfix"}>
                 <div className={"mcb-wrap-inner"}>
                 <div className={"text-center"}>
                 <h3 style={{width:"100%"}}> Apalache ESG</h3>

                 </div>
                    <table id="example" className={"table table-striped table-bordered"} style={{width:'100%'}}>
                        <thead>
                        <tr>
                            <th className={"col-sm-3"}>Fecha de publicación</th>
                            <th className={"col-sm-7"}>Documento</th>
                            <th className={"col-sm-2"}>Archivo</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                              <td>2020-10-05</td>
                              <td>Addendum ESG - CADU </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/CADU-Addendum-10-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-10-05</td>
                              <td>Addendum ESG - BAFAR </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/BAFAR-Addendum-10-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-10-05</td>
                              <td>Addendum ESG - FNOVA </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/FNOVA-Addendum-10-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>

                        <tr>
                              <td>2020-10-01</td>
                              <td>Addendum ESG - FMTY </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/FMTY-Addendum-10-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-09-28</td>
                              <td>Addendum ESG - UPSITE </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/UPSITE-Addendum-09-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-09-28</td>
                              <td>Addendum ESG - JAVER </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/JAVER-Addendum-09-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-09-23</td>
                              <td>Addendum ESG - LAMOSA </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/LAMOSA-Addendum-09-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-09-23</td>
                              <td>Addendum ESG - TMM </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/TMM-Addendum-09-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-09-23</td>
                              <td>Addendum ESG - VITRO </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/VITRO-Addendum-09-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                        <tr>
                              <td>2020-09-23</td>
                              <td>Addendum ESG - GISSA </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/GISSA-Addendum-09-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                     <tr>
                              <td>2020-09-14</td>
                              <td>Addendum ESG - QUÁLITAS </td>
                              <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/Q-Addendum-09-20.pdf">
                                  <i className={"fa fa-download"}></i> Descargar
                              </a>
                              </td>
                          </tr>
                          <tr>
                          <td>2020-09-14</td>
                          <td>Addendum ESG - HOTEL </td>
                          <td><a target="_blank" href="https://investorcloud.s3.amazonaws.com/apalache/apalacheanalisis/HOTEL-Addendum-09-20.pdf">
                              <i className={"fa fa-download"}></i> Descargar
                          </a>
                          </td>
                      </tr>



                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>

  </Layout>
)
}


export default PublicacionesLists3
